import {CLEAR, LOAD, LOAD_FAILURE, LOAD_SUCCESS} from "../reducers/reducer";

export const load = () => {
	return {
		type: LOAD
	}
};

export const loadSuccess = (data) => {
	return {
		type: LOAD_SUCCESS,
		data
	}
}

export const loadFailure = (error) => {
	return {
		type: LOAD_FAILURE,
		error
	}
}

export const clear = () => {
	return {
		type: CLEAR
	}
}
