import React, {useContext, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {apiURL} from "../../config";
import axios from "axios";
import {FaSpinner} from "react-icons/fa";
import SocialMediaButton from "../SocialMediaButton/SocialMediaButton";

const SocialMediaBlock = ({teamId}) => {
	const initialState = {loaded: false, data: {}}
	const [teamLinks, setTeamLinks] = useState(initialState);

	const [error, setError] = useState(false);

	const fetchTeamLinks = async () => {
		const url = `${apiURL}/team/${teamId}/team_links`;
		try {
			const response = await axios.get(url);
			if (response.data.team_links) {
				setTeamLinks({loaded: true, data: response.data})
			}
		} catch (e) {
			setTeamLinks({...teamLinks, loaded: false});
			setError(true);
		}
	};

	useEffect(() => {
		(async () => {
			await fetchTeamLinks();
		})();
	}, []);
	if (teamLinks.loaded && teamLinks.data) {
		const links = teamLinks.data.team_links;
		const col_size = links.length === 1 ? 12 : !links.length % 2 ? 3 : 6;
		return (
			<div className={"d-flex flex-column h-100"}>
				<h2 className={"mb-4 text-center"}>Follow Us</h2>
				<Row>
					{
						links.length ?
							links.map((link, index) => {
								return (
									<Col xs={12} sm={col_size} className={"mb-3 pill-column"} key={index}>
										<SocialMediaButton link={link}/>
									</Col>
								);
							})
							:
							<Col xs={12}>
								<p className={"text-center"}>Sorry, we can't find any links right now. Please check
									back later.</p>
							</Col>
					}
				</Row>
			</div>
		);
	} else if (teamLinks.loaded === false && error) {
		return <h3>Something went wrong</h3>;
	}
	return (
		<div className={"d-flex align-items-center justify-content-center"}>
			<FaSpinner className={"icon-spin"} color={"black"} size={20}/>
		</div>
	);
};

export default SocialMediaBlock;
