import React, {createContext, useEffect, useReducer} from "react";
import {reducer} from "../reducers/reducer";
import {isEmpty} from "../helpers/helpers";

const initialValues = {
	loaded: false,
	loading: false,
	data: {}
};

export const TeamContext = createContext({});

const TeamProvider = ({children}) => {

	const initialState = JSON.parse(sessionStorage.getItem("team")) &&
		{
			loaded: true,
			loading: false,
			data: JSON.parse(sessionStorage.getItem("team"))
		} || initialValues;

	const [team, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (team.data === {}) {
			sessionStorage.setItem("team", JSON.stringify(team.data));
		}
	}, [team]);

	return (
		<TeamContext.Provider value={{team, dispatch}}>
			{children}
		</TeamContext.Provider>
	);
};

export default TeamProvider;
