import React, {useEffect, useState} from "react";
import {navigate} from "@reach/router";
import {Container} from "react-bootstrap";
import ls from 'local-storage';

const Home = () => {
	const [market_id, setMarketId] = useState(null)

	useEffect(() => {
		// Retrieve Cache Storage
		const fetchCachedMarket = async () => {
			try {
				const cache = await caches.open('MARKET');
				const response = await cache.match('/market_cache');

				if (!response) {
					return null;
				}
				const responseBody = await response.json();
				return `${responseBody['id']}`
			} catch (e) {
				try {
					setMarketId(ls.get('market_id') || null);
					if (market_id && market_id !== "undefined") {
						return market_id;
					}
				} catch (e) {
					console.log("The site has no Cache/Local Storage data");
				}

			}
		}

		(async () => {
			const market_id = await fetchCachedMarket();

			market_id && await navigate(`/market/${market_id}`);
		})();

	}, []);

	return (
		<Container className={"vh-100 align-items-center justify-content-center d-flex"}>
			<h1 className={"font-weight-bold"}>Ask an Expert</h1>
		</Container>

	)
}

export default Home;
