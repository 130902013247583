import React from "react";
import {Col, Row} from "react-bootstrap";
import moment from "moment";

const ScheduleCard = ({store, show_time}) => {
	return (
		<div className={"location mb-3"} key={store.store_number}>
			<Row>
				<Col className={"location-name"}>
					<p className={"font-weight-bold"}>{store.retailer.name} - {store.city}</p>
				</Col>
			</Row>
			<Row className={"location-data d-flex"}>
				<Col className={"location-info d-flex align-items-center"} xs={12} md={6}>
					<p><span className={"text-capitalize"}>{store.address.toLowerCase()}</span><br/>
						{`${store.city}, ${store.state} ${store.zip}`}<br/>
						{store.phone}</p>
				</Col>
				<Col className={"location-schedule d-flex flex-column font-weight-bold"}>
					{store.visit_set.map((visit, index) => {
						const {format} = visit.start_time;
						let visit_hours_string = '';
						if (format === 'block') {
							const visit_block_start = moment(visit.start_time.visit_hours[0], ['HH:mm:ss']).format('h:mm a');
							const visit_block_end = moment(visit.start_time.visit_hours[1], ['HH:mm:ss']).format('h:mm a');
							visit_hours_string = `${visit_block_start} - ${visit_block_end}`;
						} else if (format === 'start'){
							visit_hours_string = moment(visit.start_time.visit_hours, ['HH:mm:ss']).format('h:mm a');
						}
						const date_format = show_time ? 'ddd, MMM Do' : 'dddd, MMMM Do';
						const visit_date = moment(visit.date).format(date_format)
						return (
							<Row className={"mb-2"} key={`date-${index}`}>
								<Col
									className={show_time ? "text-left" : "text-md-right"}
									xs={show_time ? 5 : 12}>
									{visit_date}
								</Col>
								{show_time &&
								<Col className={"text-right"}>
									{visit_hours_string}
								</Col>
								}
							</Row>
						);
					})}
				</Col>
			</Row>
		</div>
	);
}

export default ScheduleCard;
