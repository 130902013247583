import React, {useContext} from "react";
import {Formik, Field, useFormikContext} from "formik";
import {Form, Card, Button, Row, Col} from "react-bootstrap"
import {apiURL} from "../../config";
import {clear, load, loadFailure, loadSuccess} from "../../actions/actions";
import axios from "axios";
import {UserContext} from "../../context/UserContext";
import {AuthContext} from "../../context/AuthContext";
import PasswordBlock from "../PasswordBlock/PasswordBlock";
import {Link} from "@reach/router";

const AutoSubmitStatus = () => {
	const {values, submitForm} = useFormikContext();
	const {user} = useContext(UserContext)
	React.useEffect(() => {
		if (user.data.online !== values.online) {
			(() => submitForm())();
		}
	}, [values, user, submitForm]);
	return null;
};

const UserBlock = ({user}) => {
	const {dispatch, clearUser} = useContext(UserContext)
	const {auth, clearAuth} = useContext(AuthContext);

	const updateStatus = async (values) => {
		const url = `${apiURL}/sales_rep`;
		dispatch(load());
		try {
			const response = await axios.patch(url, {'online': values.online}, {headers: {Authorization: `JWT ${auth.data.token}`}});
			if (response.data) {
				dispatch(loadSuccess(response.data));
			}
		} catch (e) {
			dispatch(loadFailure(e));
		}
	};

	const logout = () => {
		clearUser();
		clearAuth();
	}

	return (
		<React.Fragment>
			<Row>
				<Col>
					<Card>
						<Card.Header>
							<h4 className={"m-0"}>{`${user.first_name} ${user.last_name}`}</h4>
						</Card.Header>
						<Card.Body>
							<h6>{`${user.job_title} - ${user.email}`}</h6>
						</Card.Body>
						<Card.Footer>
							<Formik
								initialValues={
									{'online': user.online}
								}
								onSubmit={values => updateStatus(values)}>
								{({getFieldProps}) => (
									<Form>
										<Field
											name={"online"}>
											{({field}) => {
												return (
													<Form.Check
														id={"online"}
														type={"switch"}
														checked={field.value}
														{...getFieldProps('online')}
														label={"Online"}
														{...field}
													/>
												);
											}}
										</Field>
										<AutoSubmitStatus/>
									</Form>
								)}
							</Formik>
						</Card.Footer>
					</Card>
				</Col>
			</Row>
			<Row className={"mt-5 mb-5 d-flex"}>
				<Col className={"order-2 order-sm-1"}>
					<Button size={"lg"} variant={"outline-secondary"}
					        onClick={logout} block>Logout</Button>
				</Col>
				{user.market_id &&
				<Col sm={7} md={8} lg={9} className={"order-sm-2"}>
					<Link to={`/market/${user.market_id}`}>
						<Button size={"lg"} block className={"mb-4 mb-sm-0"}>Back to Home</Button>
					</Link>
				</Col>
				}
			</Row>
			<Row>
				<Col>
					<PasswordBlock user_id={user.user_id}/>
				</Col>
			</Row>
		</React.Fragment>
	);

};

export default UserBlock;
