import React, {useContext} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {Formik} from "formik";
import {apiURL} from "../../config";
import axios from "axios";
import * as Yup from "yup";
import {UserContext} from "../../context/UserContext";
import {AuthContext} from "../../context/AuthContext";
import {Slide, toast} from "react-toastify";

const ChangePasswordSchema = Yup.object().shape({
	old_password: Yup.string().required('Required'),
	password: Yup.string().required('Required').min(8, "Must be at least 8 characters"),
	password2: Yup.string().required('Required').min(8, "Must be at least 8 characters")
});

const PasswordBlock = ({user_id}) => {
	const {clearUser} = useContext(UserContext)
	const {auth, clearAuth} = useContext(AuthContext);

	const passwordUpdated = () => toast('Your password was successfully changed. You can now log in with the your new password',
		{
			position: "top-center",
			autoClose: true,
			hideProgressBar: true,
			transition: Slide,
		});

	const passwordError = (e) => toast.error(e,
		{
			position: "top-center",
			autoClose: false,
			hideProgressBar: true,
			transition: Slide,
		});

	const updatePassword = async (values) => {
		const url = `${apiURL}/change_password/${user_id}`;
		try {
			const response = await axios.patch(url, values, {headers: {Authorization: `JWT ${auth.data.token}`}});
			if (response.status === 200) {
				passwordUpdated();
				logout();
			}
		} catch (e) {
			handleError(e.response.data)
		}
	}

	const handleError = (error) => {
		for (const [key, value] of Object.entries(error)) {
			if (value) {
				if (Array.isArray(value)) {
					value.map((message) => {
						return passwordError(message)
					});
				} else {
					passwordError(value[key]);
				}
			}
		}
	};

	const logout = () => {
		clearUser();
		clearAuth();
	}

	return (
		<Formik
			initialValues={
				{'old_password': '', 'password': '', 'password2': ''}
			}
			onSubmit={values => updatePassword(values)}
			validationSchema={ChangePasswordSchema}
		>
			{({getFieldProps, handleSubmit, isValid, isSubmitting, dirty, errors}) => (
				<React.Fragment>
					<Card>
						<Card.Header>
							<h4 className={"m-0"}>Change Password</h4>
						</Card.Header>
						<Card.Body>

							<Form onSubmit={handleSubmit}>
								<Form.Group controlId={"formPassword"}>
									<Form.Label>Password</Form.Label>
									<Form.Control type={"password"}
									              name={"old_password"}
									              {...getFieldProps("old_password")}
									/>
								</Form.Group>
								<Form.Group controlId={"formNewPassword"}>
									<Form.Label>New Password</Form.Label>
									<Form.Control type={"password"}
									              name={"password"}
									              {...getFieldProps("password")}
									/>
								</Form.Group>
								<Form.Group controlId={"formPasswordConfirm"}>
									<Form.Label>Confirm New Password</Form.Label>
									<Form.Control type={"password"}
									              name={"password2"}
									              {...getFieldProps("password2")}
									/>
								</Form.Group>
							</Form>

						</Card.Body>
					</Card>
					<Button size={"lg"} className={"mt-5 mb-5"} variant={"outline-secondary"} block type={"Submit"}
					        disabled={!(isValid && dirty) || isSubmitting}
					        onClick={handleSubmit}>
						Change Password
					</Button>
				</React.Fragment>
			)}
		</Formik>
	);

};

export default PasswordBlock;
