import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import ResourceButton from "../ResourceButton/ResourceButton";
import {Link} from "@reach/router";

const ResourceList = ({resources, extraResources, teamId}) => {
	const resource_count = resources.length;
	const col_size = resource_count === 1 ? 12 : !resource_count % 2 ? 3 : 6;
	return (
		<React.Fragment>
			<h2 className={"mb-3 mb-lg-5 text-center"}>Product Resources</h2>
			<Row className={"mb-3"}>
				{resource_count ? resources.map((resource, index) => {
						return (
							<Col xs={12} sm={col_size} className={"mb-3"} key={index}>
								<ResourceButton resource={resource}/>
							</Col>
						);
					}) :
					<Col xs={12}>
						<p className={"text-center"}>Sorry, we can't find any resources right now. Please check
							back later.</p>
					</Col>
				}
			</Row>
			{extraResources &&
			<Row>
				<Col>
					<Link label={"resourcesView"} to={`/member/${teamId}/resources`} id={"link_resources_view"}>
						<Button size={"lg"} variant={"outline-primary"} block>
							We have more resources for you, click here to get them!
						</Button>
					</Link>
				</Col>
			</Row>
			}
		</React.Fragment>
	);
}

export default ResourceList;
