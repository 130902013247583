import {GALink} from "../../helpers/tracking";
import React from "react";
import {Badge, Button} from "react-bootstrap";
import {AiOutlineIdcard} from "react-icons/ai";
import {storageURL} from "../../config";
import {formatPhoneNumber} from "../../helpers/helpers";

const ContactDataBlock = (
	{
		expert,
		fullData = true,
		marketId,
		supervisor = false,
		sms = true,
		email = true,
		businessHours = false,
		chatIntegration = false,
		status,
		vcard = true
	}) => {
	const vcard_url = `${storageURL}/media/vcards/${marketId}.vcard`;
	const phone = expert.phone ? formatPhoneNumber(expert.phone) : null;
	const supervisorTitle = 'District Coach';
	return (
		<React.Fragment>
			<div className={`d-flex ${businessHours ? 'flex-column-reverse' : 'flex-column'}`}>
				<div className={"d-flex flex-row align-items-baseline"}>
					<div className={"title"}>
						{supervisor ? supervisorTitle : expert.job_title}
					</div>
				</div>
				<div>
					<div className={"name"}>
						{`${expert.first_name} ${expert.last_name}`}
					</div>
				</div>
			</div>
			{expert.quote &&
			<div className={"d-flex flex-row align-items-baseline mt-1 mb-2"}>
				<div className={"w-100 quote font-italic font-weight-bold"}>"{expert.quote}"</div>
			</div>
			}
			{fullData &&
			<React.Fragment>
				{email &&
				<div className={"d-flex flex-row align-items-baseline"}>
					<div className={"w-25 email-label label"}>Email:</div>
					<div className={"w-75 email text-truncate"}>
						<GALink label={"emailClick"} to={`mailto:${expert.email}`} id={"link_email"}>
							{expert.email}
						</GALink>
					</div>
				</div>
				}
				{phone && status.online &&
				<React.Fragment>
					<div className={"d-flex flex-row align-items-baseline"}>
						<div className={"w-25 phone-label label"}>Phone:</div>
						<div className={"w-75 phone"}>
							<GALink label={"phoneClick"} to={`tel:${phone}`} id={"link_phone"}>
								{`${phone}`}
							</GALink>
						</div>
					</div>
					{(sms && !chatIntegration) &&
					<GALink label={"smsClick"} to={`sms:${phone}`} id={"link_sms"}>
						<Button variant="primary" size={"lg"} className={"mt-3"} block>SMS</Button>
					</GALink>
					}
				</React.Fragment>
				}
			</React.Fragment>
			}
			<div className={"d-flex flex-row align-items-baseline mt-1"}>
				{!status.online &&
				<div className={"status mr-auto"}>
					<Badge variant="warning">Offline</Badge>
				</div>}
				{fullData && vcard &&
				<React.Fragment>
					<GALink label={"vcardClick"} to={vcard_url} id={"link_vcard"}
					        className={`text-link sm-text mr-2 ${status.online ? 'order-2' : ''}`}>
						Download vCard
					</GALink>
					<div className={"pr-2"}>
						<AiOutlineIdcard/>
					</div>
				</React.Fragment>
				}
			</div>
		</React.Fragment>

	);
};

export default ContactDataBlock;

