import React, {useContext, useEffect} from "react";
import TeamLayout from "../TeamLayout/TeamLayout";
import LoginBlock from "../../components/LoginBlock/LoginBlock";
import {AuthContext} from "../../context/AuthContext";
import {Col, Row} from "react-bootstrap";
import {navigate} from "@reach/router";
import {FaSpinner} from "react-icons/fa";
import {PageView} from "../../helpers/tracking";

const Login = ({teamId}) => {
	const {auth} = useContext(AuthContext)

	useEffect(() => {
		if (auth.loaded) {
			navigate(`/${teamId}/user`);
		}
	}, [auth]);

	useEffect(() => {
		PageView(`Login - ${teamId}`)
	},[])

	return (
		<TeamLayout teamId={teamId}>
			<Row className={"d-flex justify-content-center"}>
				<Col id={"login-block"} className={"market-block"}>
					<LoginBlock/>
				</Col>
			</Row>
		</TeamLayout>
	)


}

export default Login;
