import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {Formik} from "formik";
import * as Yup from "yup";
import {Button, Form} from "react-bootstrap";
import {MemberContext} from "../../context/MemberContext";
import {apiURL} from "../../config";
import {FaSpinner} from "react-icons/fa";

const LoginSchema = Yup.object().shape({
	// email: Yup.string().email('Invalid email').required('Required'),
	first_name: Yup.string().required('Required'),
	store_number: Yup.string().required('Required'),
	retailer: Yup.string().required('Required')
});

const MemberLoginBlock = ({teamId, marketId}) => {
	const initialState = {loaded: false, data: []}
	const {setMember} = useContext(MemberContext);
	const [error, setError] = useState(false);
	const [retailers, setRetailers] = useState(initialState);

	useEffect(() => {
		(async () => {
			await fetchTeamRetailers()
		})();
	}, []);

	const fetchTeamRetailers = async () => {
		const url = `${apiURL}/team/${teamId}/retailers`;
		setRetailers({...retailers, loading: true})
		try {
			const response = await axios.get(url);
			setRetailers({loaded: true, data: response.data});
			return true;
		} catch (e) {
			setRetailers({...retailers, loaded: false})
			setError(true);
			return false;
		}
	};

	const memberLogin = async (values) => {
		const url = `${apiURL}/member/${marketId}`;
		try {
			const response = await axios.post(url, values);
			setMember(response.data);
			return true;
		} catch (e) {
			setError(true);
			// if (e.response.status === 400 && e.response.data.email.length) {
			// 	try {
			// 		const response = await axios.patch(url,
			// 			{'retailer': values.retailer},
			// 			{params: {email: values.email}});
			// 		setMember(response.data);
			// 		return true;
			// 	} catch (e) {
			//
			// 	}
			// }
		}
	};

	if (retailers.loading && !retailers.loaded) {
		return (
			<div className={"d-flex align-items-center justify-content-center"}>
				<FaSpinner className={"icon-spin"} color={"black"} size={20}/>
			</div>
		);
	}

	return (
		<React.Fragment>
			<h2 className={"mb-3 mb-lg-5 text-center"}>Resources</h2>
			<p className={"text-center"}>Have questions? Want to learn more? Enter your information below to access
				additional resources.</p>
			<Formik
				initialValues={
					{first_name: '', store_number: '', retailer: undefined}
				}
				onSubmit={values => {
					(() => memberLogin(values))();
				}}
				validationSchema={LoginSchema}
			>
				{({getFieldProps, handleSubmit, isValid, isSubmitting, dirty}) => (
					<Form onSubmit={handleSubmit}>
						<Form.Group controlId={"formFirstName"}>
							<Form.Label>First Name</Form.Label>
							<Form.Control type={"text"}
							              name={"first_name"}
							              {...getFieldProps('first_name')}
							/>
						</Form.Group>
						<Form.Group controlId={"formStoreNumber"}>
							<Form.Label>Store Number</Form.Label>
							<Form.Control type={"text"}
							              name={"store_number"}
							              {...getFieldProps('store_number')}
							/>
						</Form.Group>
						<Form.Group controlId={"formRetailer"}>
							<Form.Label>Select your retailer</Form.Label>
							<Form.Control as={"select"}
							              {...getFieldProps('retailer')}
							>
								<option value={''}>---</option>
								{retailers.data.length && retailers.data.map((retailer) => {
									return <option value={retailer.id}
									               key={retailer.id}>{retailer.name}</option>
								})}
							</Form.Control>
						</Form.Group>
						<Button variant={"primary"} block size={"lg"} type={"Submit"}
						        disabled={!(isValid && dirty) || isSubmitting}>
							Go
						</Button>
					</Form>
				)}
			</Formik>
		</React.Fragment>
	);
};

export default MemberLoginBlock;
