import React, {createContext, useEffect, useState} from "react";

export const MemberContext = createContext({email: '', retailer: ''});

const MemberProvider = ({children}) => {
	const initialState = JSON.parse(localStorage.getItem("member")) || {};

	const [member, setMember] = useState(initialState);

	useEffect(() => {
		localStorage.setItem("member", JSON.stringify(member))
	}, [member]);

	const clearMembership = () => {
		localStorage.removeItem("member");
		setMember({})
	}

	return (
		<MemberContext.Provider value={{member, setMember, clearMembership}}>
			{children}
		</MemberContext.Provider>
	)
};

export default MemberProvider;
