export const LOAD = "LOAD";
export const LOAD_SUCCESS = "LOAD_SUCCESS";
export const LOAD_FAILURE = "LOAD_FAILURE";
export const CLEAR = "CLEAR";

export const reducer = (state, action) => {
	switch (action.type) {
		case LOAD_SUCCESS:
			return {
				loaded: true,
				loading: false,
				data: {...state.data, ...action.data}
			}
		case LOAD:
			return {
				loaded: false,
				loading: true,
			}
		case LOAD_FAILURE:
			return {
				loaded: false,
				loading: false,
				error: action.error
			}
		case CLEAR:
			return {
				loaded: false,
				loading: false,
			}
		default:
			return state;
	}
};
