import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {Formik} from "formik";
import * as Yup from "yup";
import {Button, Form} from "react-bootstrap"
import {apiURL} from "../../config";
import {AuthContext} from "../../context/AuthContext";
import {load, loadFailure, loadSuccess} from "../../actions/actions";
import {Slide, toast} from "react-toastify";
import {FaSpinner} from "react-icons/fa";

const LoginSchema = Yup.object().shape({
	username: Yup.string().email('Invalid email').required('Required'),
	password: Yup.string().required('Required')
})

const LoginBlock = () => {
	const {dispatch} = useContext(AuthContext)
	const [error, setError] = useState(false)

	const loginError = () => toast.error(error, {
		position: "top-center",
		autoClose: false,
		hideProgressBar: true,
		transition: Slide,
		onClose: setError(false)
	});

	const userLogin = async (values) => {
		const url = `${apiURL}/token`;
		dispatch(load());
		try {
			const response = await axios.post(url, values);
			if (response.data && response.data.token) {
				dispatch(loadSuccess(response.data))
			}
		} catch (e) {
			dispatch(loadFailure(e));
			if (e.response.status === 400) {
				setError(e.response.data.non_field_errors[0])
			} else {
				setError("Uh oh! Something went wrong. Please try again.")
			}
		}
	};

	useEffect(() => {
		error && loginError();
	}, [error])

	return (
		<React.Fragment>
			<h2 className={"mb-3 mb-lg-5 text-center"}>User Access</h2>
			<Formik
				initialValues={
					{username: '', password: ''}
				}
				onSubmit={values => userLogin(values)}
				validationSchema={LoginSchema}
			>
				{({getFieldProps, handleSubmit, isValid, isSubmitting, dirty, errors}) => (
					<Form onSubmit={handleSubmit}>
						<Form.Group controlId={"formUsername"}>
							<Form.Label>Email</Form.Label>
							<Form.Control type={"email"}
							              name={"username"}
							              {...getFieldProps('username')}
							/>
						</Form.Group>
						<Form.Group controlId={"formPassword"}>
							<Form.Label>Password</Form.Label>
							<Form.Control type={"password"}
							              name={"password"}
							              autoComplete={"on"}
							              {...getFieldProps('password')}
							/>
						</Form.Group>
						<Button variant={"primary"} block size={"lg"} type={"Submit"}
						        disabled={!(isValid && dirty) || isSubmitting}>
							{isSubmitting ? <FaSpinner className={"icon-spin"} color={'white'}/> : 'Login'}
						</Button>
					</Form>
				)}
			</Formik>
		</React.Fragment>
	)
}

export default LoginBlock;
