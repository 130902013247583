import {Button} from "react-bootstrap";
import {GALink} from "../../helpers/tracking";
import React from "react";
import {HiExternalLink} from "react-icons/hi";
import {IoMdDocument} from "react-icons/io";
import {MdPictureAsPdf} from "react-icons/md";
import {FaImage} from "react-icons/fa";

const ResourceButton = ({resource, classes}) => {

	const getResourceIcon = (resource) => {
		if (!resource.file) return <HiExternalLink/>;
		let icon = <IoMdDocument/>
		const re = /(?:\.([^.]+))?$/;
		const file_extension = re.exec(resource.file);
		switch (file_extension[1]) {
			case 'pdf':
				icon = <MdPictureAsPdf/>
				break;
			case 'jpg':
				icon = <FaImage/>
				break
			case 'gif':
				icon = <FaImage/>
				break
			case 'jpeg':
				icon = <FaImage/>
				break
			case 'png':
				icon = <FaImage/>
				break
			default:
				break;
		}
		return icon;
	}

	return (
		<GALink to={resource.file ? resource.file : resource.link}
		        label={`resourceClick - ${resource.name}`}
		        target={"_blank"}
		        className={"resource-link"}
		        id={`resource-${resource.name.replace(/ /g, "-").toLowerCase()}`}
		        rel={"noreferrer"}>
			<Button size={"lg"} variant={"outline-primary"} block
			        className={`align-items-center d-flex justify-content-between ${classes}`}>
				<div className={"text-left text-truncate pr-5"}>{resource.name}</div>
				{getResourceIcon(resource)}
			</Button>
		</GALink>
	);
};

export default ResourceButton
