import React, {useContext, useEffect} from "react";
import {Row, Col} from "react-bootstrap";

import TeamLayout from "../TeamLayout/TeamLayout";
import {MemberContext} from "../../context/MemberContext";
import {navigate} from "@reach/router";
import MemberLoginBlock from "../../components/MemberLoginBlock/MemberLoginBlock";
import {PageView} from "../../helpers/tracking";

const MemberLogin = ({teamId}) => {
	const {member, setMember} = useContext(MemberContext);

	useEffect(() => {
		if (member && member.email) {
			navigate(`/member/${teamId}/resources`);
		}
	}, [member]);

	useEffect(() => {
		PageView(`Member Login - ${teamId}`)
	},[])

	return (
		<TeamLayout teamId={teamId}>
			<Row className={"d-flex justify-content-center"}>
				<Col id={"member-login-block"} className={"market-block"}>
					<MemberLoginBlock teamId={teamId}/>
				</Col>
			</Row>
		</TeamLayout>
	);
};

export default MemberLogin;
