import React, {useEffect} from 'react';
import {Router} from "@reach/router";
import {ToastContainer} from 'react-toastify';

import Home from "../Home/Home";
import Market from "../Market/Market";
import MemberLogin from "../MemberLogin/MemberLogin";
import Login from "../Login/Login";
import User from "../User/User";

import {initGA, isRunningStandalone, PageView} from "../../helpers/tracking";

import {googleTrackingId} from "../../config";
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import TeamProvider from "../../context/TeamContext";
import MemberProvider from "../../context/MemberContext";
import RetailerResources from "../RetailerResources/RetailerResources";
import UsageWarning from "../../components/UsageWarning/UsageWarning";
import AuthProvider from "../../context/AuthContext";
import UserProvider from "../../context/UserContext";
import {HelmetProvider} from 'react-helmet-async';

const App = () => {

	useEffect(() => {
		initGA(googleTrackingId);
		// PageView();
		isRunningStandalone();
	}, [])

	const NotFound = () => <p>Sorry, nothing here</p>

	return (
		<AuthProvider>
			<UserProvider>
				<HelmetProvider>
					<TeamProvider>
						<MemberProvider>
							<main className={"App"}>
								<ToastContainer draggable={false} limit={1}/>
								<Router>
									<Home path={"/"}/>
									<Market path={"market/:marketId"}/>
									<MemberLogin path={"member/:teamId/login"}/>
									<RetailerResources path={"member/:teamId/resources"}/>
									<Login path={":teamId/user/login"}/>
									<User path={":teamId/user"}/>
									<NotFound default />
								</Router>
							</main>
						</MemberProvider>
					</TeamProvider>
				</HelmetProvider>
			</UserProvider>
		</AuthProvider>
	);
}

export default App;
