import React, {createContext, useEffect, useReducer} from "react";
import {reducer} from "../reducers/reducer";
import {clear} from "../actions/actions";

const initialValues = {
	loaded: false,
	loading: false,
};

export const AuthContext = createContext({});

const AuthProvider = ({children}) => {

	const initialState = JSON.parse(localStorage.getItem("auth")) &&
		{
			loaded: true,
			data: JSON.parse(localStorage.getItem("auth"))
		} || initialValues;

	const [auth, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (auth.data) {
			localStorage.setItem("auth", JSON.stringify(auth.data));
		}
	}, [auth.data]);

	const clearAuth = () => {
		localStorage.removeItem("auth");
		dispatch(clear());
	}

	return (
		<AuthContext.Provider value={{auth, dispatch, clearAuth}}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
