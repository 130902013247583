import React from "react";
import ReactGA from "react-ga-gtm";

export const initGA = (trackingId) => {
	ReactGA.initialize(trackingId, {
		debug: false,
		titleCase: false,
	});
};

export const PageView = (title = undefined) => {
	ReactGA.pageview(window.location.pathname + window.location.search, title);
};

export const eventClick = (category, action, label) => {
	ReactGA.event({
		category: category,
		action: action,
		label: label
	});
};

export const GALink = ({label, to, children, ...args}) => {
	return (
		<ReactGA.OutboundLink eventLabel={label} to={to} {...args}>
			{children}
		</ReactGA.OutboundLink>
	);
};

export const isRunningStandalone = () => {
	const launchOrigin = window.matchMedia('(display-mode: standalone)').matches ? 'home screen' : 'qr/other';
	// On GA dimension1 == aaeLaunchOrigin
	ReactGA.set({dimension1: launchOrigin});
};
