import React, {useEffect, useState} from "react";
import ls from "local-storage";
import moment from "moment";
import {Slide, toast} from "react-toastify";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Geolocation} from "../../helpers/Geolocation";
import {checkDismissal} from "../../helpers/helpers";
import {eventClick} from "../../helpers/tracking";
import styled from "styled-components";

const BannerStyledButton = styled.button`
  border-color: ${props => props.theme ? props.theme.brand_accent_color === '#FFFFFF' ? 'grey' : props.theme.brand_accent_color : 'grey'} !important;
  color: ${props => props.theme ? props.theme.brand_accent_color === '#FFFFFF' ? 'grey' : props.theme.brand_accent_color : 'grey'} !important;
`;

const GeolocationRequest = ({team, callback}) => {
	const [fetchingCurrentLocation, setFetchingCurrentLocation] = useState(false);
	const [useCurrentLocation, setUseCurrentLocation] = useState(true);
	const [currentLocationError, setCurrentLocationError] = useState(false);
	const [fetchLocationError, setFetchLocationError] = useState(false);
	const [showGeoRequest, setShowGeoRequest] = useState(checkDismissal('geolocation_dismissal'));

	const theme = {
		'brand_color': team.brand_color,
		'brand_accent_color': team.brand_accent_color
	};


	const setCurrentLatLng = (lat, lng) => {
		callback(`${lat},${lng}`);
	};

	const httpForbiddenHandler = () => {
		setFetchLocationError(true)
	}

	const geolocation = new Geolocation(
		[],
		setCurrentLatLng,
		() => {
		},
		fetchingCurrentLocation,
		setFetchingCurrentLocation,
		useCurrentLocation,
		setUseCurrentLocation,
		currentLocationError,
		setCurrentLocationError,
		setFetchLocationError,
		httpForbiddenHandler
	);

	useEffect(() => {
		const geoStatus = !!ls.get('geolocation_status');
		const dismissed = ls.get('geolocation_dismissal');
		setShowGeoRequest((!dismissed && !geoStatus) || (moment(dismissed) < moment() && !geoStatus));
		if (navigator.permissions && navigator.permissions.query) {
			navigator.permissions.query({name: 'geolocation'}).then(function (result) {
				if (result.state === 'granted') {
					geolocation.getCurrentLocation();
				}
			});
		} else if (navigator.geolocation) {
			geolocation.getCurrentLocation();
		}
		showGeoRequest && notify();
	}, []);

	const getUserCurrentLocation = (closeToast) => {
		ls.set('geolocation_status', true)
		geolocation.getCurrentLocation();
		eventClick('Geolocation Request', 'click', 'accepted');
		closeToast();
	}

	const BannerMessage = ({closeToast}) => {
		const appName = team.app_name ? team.app_name : 'Ask an Expert';
		return (
			<Container className={"container-fluid"}>
				<Row>
					<Col
						style={{color: theme.brand_accent_color === '#FFFFFF' ? 'grey' : theme.brand_accent_color}}>{appName} Wants
						to use your location.</Col>
					<Col>
						<BannerStyledButton
							type="button"
							className={"btn mt-1 btn-outline-light btn-block"}
							theme={theme}
							onClick={() => getUserCurrentLocation(closeToast)}>
							Agree
						</BannerStyledButton>
					</Col>
				</Row>
			</Container>
		)
	};

	const notify = () => toast(<BannerMessage/>,
		{
			toastId: `geo-request-${team.name.toLowerCase()}`,
			position: "top-right",
			autoClose: false,
			hideProgressBar: true,
			transition: Slide,
			closeButton: false,
			closeOnClick: false,
			draggable: true,
			onClose: () => {
				ls.set(
					'geolocation_dismissal',
					moment().add(7, 'days').toISOString()
				);
			}
		}
	);
	return <React.Fragment/>;
};

export default GeolocationRequest;
