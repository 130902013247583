import React, {useEffect, useRef} from "react";


const Canvas = ({image, width, height, crop, ...rest}) => {
	const canvasRef = useRef(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');

		const imageObj = new Image();

		imageObj.onload = drawImageActualSize

		imageObj.src = image;

		function drawImageActualSize() {
			canvas.width = width;
			canvas.height = height;

			if (crop) {
				const [startX, startY, startWidth, startHeight] = crop.split(",").map((coord) => parseInt(coord))
				context.drawImage(this, startX, startY, startWidth - startX, startHeight - startY, 0, 0, width, height);
			} else {
				context.drawImage(this, 0, 0);
			}

		}
	}, [])


	return <canvas ref={canvasRef} {...rest}/>;
}

export default Canvas;
