import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {Link} from "@reach/router";
import {Col, Container, Row, Button} from "react-bootstrap"
import ContactBlock from "../../components/ContactBlock/ContactBlock";
import ScheduleBlock from "../../components/ScheduleBlock/ScheduleBlock";
import {apiURL, pushAppServerKey, backendURL} from "../../config";
import ls from 'local-storage';
import {TeamContext} from "../../context/TeamContext";
import MemberLoginBlock from "../../components/MemberLoginBlock/MemberLoginBlock";
import {MemberContext} from "../../context/MemberContext";
import RetailerResourcesBlock from "../../components/RetailerResourcesBlock/RetailerResourcesBlock";
import TeamLayout from "../TeamLayout/TeamLayout";
import {FaChevronRight, FaSpinner} from "react-icons/fa";
import {loadSuccess} from "../../actions/actions";
import AddToHomeScreen from "../../components/AddToHomeScreen/AddToHomeScreen";
import {FiSettings} from "react-icons/fi";
import CarouselBlock from "../../components/CarouselBlock/CarouselBlock";
import FreshChat from 'react-freshchat'
import styled from "styled-components";
import SocialMediaBlock from "../../components/SocialMediaBlock/SocialMediaBlock";
import {eventClick, PageView} from "../../helpers/tracking";
import GeolocationRequest from "../../components/GeolocationRequest/GeolocationRequest";
import ReactGA from "react-ga-gtm";

const ConnectBanner = styled.div`
  top: 19px;
  right: 52px;
  z-index: 99999;
  background-color: ${props => props.brandColor};
  font-weight: bold;
  padding: 10px 30px 10px 10px;
  border-radius: 5px;
  color: ${props => props.accentColor};
  font-size: 14px;
`;

export const RetailerResourcesLoginBlock = ({market, member, items}) => {
	return (
		<React.Fragment>
			{
				member.retailer ?
					<RetailerResourcesBlock teamId={market.team.class_name} items={items}/>
					:
					<MemberLoginBlock teamId={market.team.class_name} marketId={market.id}/>
			}
		</React.Fragment>
	);

}

const Market = ({marketId}) => {
	const initialState = {loaded: false, loading: false, data: {}}

	const [market, setMarket] = useState(initialState);
	const [error, setError] = useState(false);
	const [status, setStatus] = useState(initialState);
	const [location, setLocation] = useState(false);
	const [sentPageView, setSentPageView] = useState(false);

	const {dispatch} = useContext(TeamContext);
	const {member} = useContext(MemberContext)

	const fetchMarket = async () => {
		const url = `${apiURL}/market/${marketId}`;
		setMarket({...market, loading: true})
		try {
			const response = await axios.get(url);
			setMarket({...market, loaded: true, data: response.data});
			ls.set('market_id', `${marketId}`);
			console.log('Version 0.3.3')

			// Cache Storage for iOS PWA
			const cache = await caches.open('MARKET');
			const marketResponse = JSON.stringify({
				'id': marketId
			});
			const cachedResponse = new Response(marketResponse);
			await cache.put('/market_cache', cachedResponse);

			dispatch(loadSuccess(response.data.team));

		} catch (e) {
			setMarket({...market, loaded: false, loading: false})
			setError(true)
		}
	};

	const fetchStatus = async () => {
		const url = `${apiURL}/market/${marketId}/status`;
		setStatus({...status, loading: true})
		try {
			const response = await axios.get(url);
			setStatus({...status, loaded: true, data: response.data});

		} catch (e) {
			setStatus({...status, loaded: false, loading: false})
			setError(true)
		}
	}

	useEffect(() => {
		(async () => {
			await fetchMarket();
		})();

	}, []);

	useEffect(() => {
		(async () => {
			await fetchStatus();
		})();
	}, []);

	useEffect(() => {
		if (Object.keys(market.data).length) {
			if (location) {
				ReactGA.set({dimension3: location});
			}
			if (!sentPageView) {
				PageView(market.data.name)
				setSentPageView(true);
			}
		}
	}, [market.data, location])


	useEffect(() => {
		if (window.fcWidget) {
			window.fcWidget.on("widget:opened", function (resp) {
				eventClick('Freshchat', 'click', 'open chat');
			});
			// window.fcWidget.on("widget:closed", function (resp) {
			// 	eventClick('Freshchat', 'click', 'closed chat')
			// });
		}
	}, [window.fcWidget])

	useEffect(() => {
		if (window.fcWidget && member) {
			window.fcWidget.user.setFirstName(member.first_name);
			window.fcWidget.user.setProperties({
				storeNumber: member.store_number
			});
		}
	}, [member])

	if (market.loaded) {
		const {team} = market.data;
		const teamId = team.class_name
		return (
			<React.Fragment>
				<AddToHomeScreen/>
				{team.chat_integration &&
					<div className={"d-flex position-relative"}>
						<ConnectBanner
							className={"position-absolute"}
							brandColor={team.brand_accent_color}
							accentColor={team.brand_color}
						>
							Connect Now! <FaChevronRight size={10}/>
						</ConnectBanner>
						<FreshChat
							token={team.chat_integration}
							config={{
								headerProperty: {
									appName: team.app_name ? team.app_name : 'Ask An Expert',
									backgroundColor: team.brand_accent_color,
									foregroundColor: team.brand_color,
								}
							}}
						/>
					</div>
				}
				<TeamLayout teamId={teamId}>
					<GeolocationRequest team={team} callback={setLocation}/>
					<Row
						className={`xpert-row`}>
						<Col md={12} lg={6}
						     className={"d-flex align-items-center justify-content-center py-4"}
						     id={"user-info-block"}>
							<ContactBlock data={market.data} status={status}/>
						</Col>
						{market.data.team.promotion_set && !!market.data.team.promotion_set.length &&
							<Col md={12} lg={6}
							     className={"p-0 w-100 d-flex justify-content-center overflow-hidden"}
							     id={"promotion-block"}>
								<CarouselBlock promotions={market.data.team.promotion_set}/>
							</Col>
						}
					</Row>
					{market.data.team.retailer_specific_resources &&
						<Row className={"d-flex justify-content-center"}>
							<Col className={"market-block"} id={"member-login-retailer-resources-block"}>
								<RetailerResourcesLoginBlock market={market.data} member={member} items={4}/>
							</Col>
						</Row>
					}
					{market.data.team.schedule_active &&
						<Row className={"d-flex justify-content-center"}>
							<Col className={"market-block"} id={"schedule-block"}>
								<ScheduleBlock market_id={marketId} show_time={market.data.team.show_visit_hours}/>
							</Col>
						</Row>
					}
					{market.data.team.display_social_media_links &&
						<Row className={"d-flex justify-content-center"}>
							<Col className={"market-block"} id={"schedule-block"}>
								<SocialMediaBlock teamId={market.data.team.class_name}/>
							</Col>
						</Row>
					}
					<Row className={"d-flex justify-content-center"}>
						<Col
							className={"d-flex rep-settings-block align-items-center justify-content-center d-flex p-3"}
							id={"member-login-retailer-resources-block"}>
							<Link to={`/${teamId}/user/login`}>
								<FiSettings color={"rgb(177,177,177)"} size={16}/>
							</Link>
						</Col>
					</Row>
				</TeamLayout>
			</React.Fragment>
		);
	} else if (error) {
		return (
			<Container className={"vh-100 align-items-center justify-content-center d-flex"}>
				Can't find Market
			</Container>
		);
	} else {
		return <FaSpinner className={"icon-spin"}/>
	}
}

export default Market;
