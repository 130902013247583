import styled from "styled-components";
import React, {useContext} from "react";
import {TeamContext} from "../../context/TeamContext";
import {GALink} from "../../helpers/tracking";


const SocialMediaStyledButton = styled.div`
  height: 100px;
  border-radius: 5px;
  border: 1px solid rgb(216, 216, 216);
  padding: 10px;
  background-color: ${props => props.theme ? props.theme.brand_color : 'white'} !important;
  box-shadow: rgba(0, 0, 0, 0.14) 0 3px 4px 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;

  img {
    max-width: 300px;
    max-height: 60px;
  }`;

const SocialMediaButton = ({link}) => {
	const {team} = useContext(TeamContext);
	const theme = link.themed ? {
		'brand_color': team.data.brand_color,
		'brand_accent_color': team.data.brand_accent_color
	} : link.themed;

	return (
		<GALink to={link.url}
		        label={`socialClick - ${link.name}`}
		        target={"_blank"}
		        id={`social-link-${link.name.replace(/ /g, "-").toLowerCase()}`}
		        rel={"noreferrer"}>
			<SocialMediaStyledButton theme={theme}>
				{
					link.image ?
						<img src={link.image} alt={link.name}/>
						:
						link.name
				}
			</SocialMediaStyledButton>
		</GALink>
	)

};

export default SocialMediaButton;
