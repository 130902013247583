import React, {useContext, useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet-async";
import MarketHeader from "../../components/MarketHeader/MarketHeader";
import {TeamContext} from "../../context/TeamContext";
import {apiURL} from "../../config";
import axios from "axios";
import {FaSpinner} from "react-icons/fa";
import {load, loadFailure, loadSuccess} from "../../actions/actions";
import UsageWarning from "../../components/UsageWarning/UsageWarning";

const origin = window.location.origin;

const genericManifest = {
	"short_name": "Ask an Expert",
	"name": "Ask an Expert",
	"icons": [
		{
			"src": `${origin}/favicon.ico`,
			"sizes": "64x64",
			"type": "image/x-icon"
		},
		{
			"src": `${origin}/logo192.png`,
			"type": "image/png",
			"sizes": "192x192"
		},
		{
			"src": `${origin}/logo512.png`,
			"type": "image/png",
			"sizes": "512x512"
		}
	],
	"start_url": origin,
	"display": "standalone",
	"theme_color": "#000000",
	"background_color": "#ffffff"
}


const TeamLayout = ({children, teamId}) => {
	const {team, dispatch} = useContext(TeamContext);

	const fetchTeamData = async (team_id) => {
		const url = `${apiURL}/team/${team_id}`;
		dispatch(load());
		try {
			const response = await axios.get(url);
			dispatch(loadSuccess(response.data));
		} catch (e) {
			dispatch(loadFailure(e));
		}
	};

	const buildManifest = () => {
		const manifestData = genericManifest;
		const pathName = window.location.pathname.split("/");
		manifestData.short_name = team.data.app_name ? team.data.app_name : manifestData.short_name;
		manifestData.name = team.data.app_name ? team.data.app_name : manifestData.name;
		manifestData.theme_color = team.data.brand_color ? team.data.brand_color : manifestData.theme_color;
		manifestData.background_color = team.data.brand_accent_color ? team.data.brand_accent_color : manifestData.background_color;
		manifestData.icons[2].src = team.data.app_icon ? team.data.app_icon : manifestData.icons[2].src;
		manifestData.icons[1].src = team.data.app_icon_mid ? team.data.app_icon_mid : manifestData.icons[1].src;
		manifestData.icons[0].src = team.data.app_ico ? team.data.app_ico : manifestData.icons[0].src;
		if (pathName.length > 2 && pathName[1] === 'market') {
			manifestData.start_url = window.location.href;
		}
		const blob = new Blob([JSON.stringify(manifestData)], {type: "application/json"});
		return URL.createObjectURL(blob);
	};

	useEffect(() => {
		if (!team.loaded && !team.loading) {
			(async () => await fetchTeamData(teamId))();
		}
	}, []);

	useEffect(() => {
		if (team.loaded) {
			const manifestURL = buildManifest();
			document.querySelector('#aae-manifest').setAttribute('href', manifestURL);
		}
	}, [team.loaded])


	if (team.loaded) {
		const appName = team.data.app_name ? team.data.app_name : 'Ask an Expert';
		const favicon = team.data.app_ico ? team.data.app_ico : '/favicon.ico';
		const appleAppIcon = team.data.app_icon_mid ? team.data.app_icon_mid : '/logo192.png';


		return (
			<div className={`market ${team.data.class_name}`}>
				<UsageWarning team={team.data}/>
				<Helmet>
					<title>{appName}</title>
					<link href={favicon} type="image/x-icon" rel="icon"/>
					<link href={favicon} type="image/x-icon" rel="shortcut icon"/>
					<link href={appleAppIcon} rel="apple-touch-icon"/>
				</Helmet>
				<MarketHeader team={team.data}/>
				<Container fluid id={"team-layout"}>
					{children}
				</Container>
			</div>
		);
	} else {
		return (
			<div className={"d-flex align-items-center justify-content-center vh-100"}>
				<FaSpinner className={"icon-spin"} color={"black"} size={40}/>
			</div>
		);
	}
};

export default TeamLayout;
