import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import {apiURL} from "../../config";
import {navigate} from "@reach/router";
import axios from "axios";
import {FaSpinner} from "react-icons/fa";
import {UserContext} from "../../context/UserContext";
import {load, loadFailure, loadSuccess} from "../../actions/actions";
import {Col, Row} from "react-bootstrap";
import TeamLayout from "../TeamLayout/TeamLayout";
import UserBlock from "../../components/UserBlock/UserBlock";
import {PageView} from "../../helpers/tracking";

const User = ({teamId}) => {
	const {auth} = useContext(AuthContext)
	const {user, dispatch} = useContext(UserContext)


	useEffect(() => {
		if (!auth.loaded) {
			navigate(`/${teamId}/user/login`);
		} else {
			(() => fetchUser())();
		}
	}, [auth]);

	useEffect(() => {
		PageView(`User - ${teamId}`)
	},[])

	const fetchUser = async () => {
		const url = `${apiURL}/sales_rep`;
		dispatch(load());
		try {
			const response = await axios.get(url, {headers: {Authorization: `JWT ${auth.data.token}`}})
			if (response.data) {
				dispatch(loadSuccess(response.data));
			}
		} catch (e) {
			dispatch(loadFailure(e));
		}
	};
	return (
		<TeamLayout team_id={teamId}>
			<Row className={"d-flex justify-content-center"}>
				<Col className={"market-block justify-content-center"}
				     id={"user-status-block"}>
					{user.loaded ?
						<UserBlock user={user.data}/> :
						<Row>
							<Col className={"d-flex justify-content-center"}>
								<FaSpinner className={"icon-spin"} color={'black'}/>
							</Col>
						</Row>
					}
				</Col>
			</Row>
		</TeamLayout>
	);
}

export default User;
