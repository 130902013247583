import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {MemberContext} from "../../context/MemberContext";
import {apiURL, backendURL, pushAppServerKey} from "../../config";
import {FaSpinner} from "react-icons/fa";
import CategoryList from "../CategoryList/CategoryList";
import ResourceList from "../ResourceList/ResourceList";
import {Button, Col, Row} from "react-bootstrap";
import {urlBase64ToUint8Array} from "../../helpers/helpers";


const RetailerResourcesBlock = ({teamId, items}) => {
	const initialState = {loaded: false, data: {}}

	const {member, clearMembership} = useContext(MemberContext);
	const [retailerResources, setRetailerResources] = useState(initialState);
	const [error, setError] = useState(false);
	const [subscribedToPush, setSubscribedToPush] = useState(false);

	const fetchResources = async () => {
		const url = `${apiURL}/team/${teamId}/resources`;
		try {
			const response = await axios.get(url, {
				params: {retailer_id: member.retailer, items: items}
			});
			if (response.data.retailer) {
				setRetailerResources({loaded: true, data: response.data});
			} else {
				clearMembership();
			}
		} catch (e) {
			setRetailerResources({...retailerResources, loaded: false});
			setError(true);
		}
	};

	const requestNotificationPermission = (teamName) => {
		Notification.requestPermission().then((result) => {
			if (result === 'granted') {
				subscribeUserToPush(teamName);
			}
		});
	};

	const subscribeUserToPush = (teamName) => {
		navigator.serviceWorker.getRegistrations().then(registrations => {
			const registration = registrations[0]
			const subscribeOptions = {
				userVisibleOnly: true,
				applicationServerKey: urlBase64ToUint8Array(pushAppServerKey,),
			};
			return registration.pushManager.subscribe(subscribeOptions);
		}).then(async (pushSubscription) => {
			const browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0].toLowerCase();
			const data = {
				status_type: 'subscribe',
				subscription: pushSubscription.toJSON(),
				browser: browser,
				group: teamName,
			};
			const url = `${backendURL}/webpush/save_information`;
			try {
				await axios.post(url, data, {
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: "include"
				});
				setSubscribedToPush(true);
			} catch (e) {
				console.log(e)
			}
			return pushSubscription;
		});
	}

	const checkForSubscription = () => {
		navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
			serviceWorkerRegistration.pushManager.getSubscription()
				.then((subscription) => {
					if (!subscription) {
						setSubscribedToPush(false)
						return;
					}
					// Keep your server in sync with the latest subscriptionId
					// sendSubscriptionToServer(subscription);
					// showCurlCommand(subscription);

					setSubscribedToPush(true);
				})
				.catch((err) => {
					console.error(`Error during getSubscription(): ${err}`);
				});
		})
	};

	useEffect(() => {
		(async () => {
			await fetchResources();
			checkForSubscription();
		})();
	}, []);

	useEffect(() => {
		if (window.fcWidget && retailerResources.loaded) {
			window.fcWidget.user.setProperties({
				retailer: retailerResources.data.retailer.name
			});
		}
	}, [retailerResources]);


	if (retailerResources.loaded && retailerResources.data) {
		const {retailer, resources, categories} = retailerResources.data;
		const retailer_logo = retailer && retailer.logo ? retailer.logo : false;
		return (
			<React.Fragment>
				{retailer_logo &&
					<div className={"text-center"}>
						<img src={retailer_logo}
						     className={"img-fluid"}
						     id={"retailer-logo"}
						     alt={`${retailer && retailer.name ? `${retailer.name} logo` : 'logo'}`}/>
					</div>
				}
				{categories ?
					<CategoryList categories={categories} resources={resources}/> :
					<ResourceList resources={resources} extraResources={retailerResources.data.extra_resources}
					              teamId={teamId}/>
				}
				{('serviceWorker' in navigator && 'PushManager' in window && !subscribedToPush) &&
					<Row>
						<Col className={"market-block"} id={"notifications-subscription-block"}>
							<p className={"text-center"}>To receive notifications when new resources are available, click below</p>
							<Button
								block
								size={"lg"}
								onClick={() => requestNotificationPermission(`${teamId}:${retailer.id}`)}>
								Subscribe for Notifications</Button>
						</Col>
					</Row>
				}
			</React.Fragment>);

	} else if (retailerResources.loaded === false && error) {
		return <h3>Something went wrong</h3>;
	}
	return (
		<div className={"d-flex align-items-center justify-content-center"}>
			<FaSpinner className={"icon-spin"} color={"black"} size={20}/>
		</div>
	);

};

export default RetailerResourcesBlock
