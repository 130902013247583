import React from "react";
import {Button, Badge} from "react-bootstrap";

import Canvas from "../Canvas/Canvas";
import {formatPhoneNumber, isEmpty} from "../../helpers/helpers";

import {FaSpinner, FaUser} from "react-icons/fa";
import {GALink} from "../../helpers/tracking";
import ContactDataBlock from "../ContactDataBlock/ContactDataBlock";

const get_backup = ({sales_representative_backup, sales_representative_alternate_backup}, status) => {
	if (sales_representative_backup && sales_representative_backup.online) {
		return sales_representative_backup;
	} else if (sales_representative_alternate_backup && sales_representative_alternate_backup.online) {
		return sales_representative_alternate_backup
	} else return false;
};

const displayBackup = ({sales_representative_backup, sales_representative_alternate_backup, team}, status) => {
	const {sales_representative} = status.data
	if (status.loaded) {
		if (!sales_representative.online) {
			if (status.data.sales_representative_backup && status.data.sales_representative_backup.online) {
				return <BackupDataBlock backup={sales_representative_backup} chatIntegration={team.chat_integration}/>
			} else if (status.data.sales_representative_backup && !status.data.sales_representative_backup.online && status.data.sales_representative_alternate_backup.online) {
				return <BackupDataBlock backup={sales_representative_alternate_backup}
				                        chatIntegration={team.chat_integration}/>
			}
		} else return <React.Fragment/>
	} else {
		return <FaSpinner className={"icon-spin"} size={16}/>;
	}
};

const BackupDataBlock = ({backup, chatIntegration}) => {
	const backup_phone = backup.phone ? formatPhoneNumber(backup.phone) : null;
	return (
		<React.Fragment>
			<div className={"mt-3 mb-2"} style={{fontSize: 14}}>
				Need to connect now?
			</div>
			<div>
				<div className={"name backup"}>
					{`${backup.first_name} ${backup.last_name}`}
				</div>
			</div>
			{!!backup.email &&
				<div className={"d-flex flex-row align-items-baseline"}>
					<div className={"w-25 email-label label"}>Email:</div>
					<div className={"w-75 email text-truncate"}>
						<GALink label={"emailClick"} to={`mailto:${backup.email}`} id={"link_email"}>
							{backup.email}
						</GALink>
					</div>
				</div>
			}
			{backup_phone &&
				<React.Fragment>
					<div className={"d-flex flex-row align-items-baseline"}>
						<div className={"w-25 phone-label label"}>Phone:</div>
						<div className={"w-75 phone"}>
							<GALink label={"phoneClick"} to={`tel:${backup_phone}`} id={"link_phone"}>
								{`${backup_phone}`}
							</GALink>
						</div>
					</div>
					{!chatIntegration &&
						<GALink label={"smsClick"} to={`sms:${backup_phone}`} id={"link_sms"}>
							<Button variant="primary" size={"lg"} className={"mt-3"} block>SMS</Button>
						</GALink>
					}
				</React.Fragment>
			}
		</React.Fragment>
	);
};

const ContactBlock = ({data, status}) => {
	const marketId = data.id;
	const supervisor = data.supervisor;
	const expert = data.sales_representative;
	const clientSupported = data.client_supported_market;
	const chatIntegration = data.team.chat_integration;
	const vcardAvailability = data.team.vcard_availability;
	// const backup = get_backup(data, status)
	// const backup_phone = backup ? formatPhoneNumber(backup.phone) : null;
	if (isEmpty(expert)) {
		return 'No Expert assigned to this Market'
	}
	return (
		<div className={"d-flex justify-content-center user-info align-items-center"}>
			<div className={"main-user d-flex"}>
				{(supervisor && !clientSupported) &&
					<div className={"user-picture"} id={"supervisor"}>
						{supervisor.profile_picture ?
							<Canvas image={supervisor.profile_picture} width={150} height={150}
							        crop={supervisor.cropping} className={"canvas-user-profile-picture"}/>
							:
							<div className={"user-icon d-flex justify-content-center align-items-center"}>
								<FaUser color={"white"} size={100}/>
							</div>
						}
					</div>
				}
				<div className={`user-picture ${supervisor && !clientSupported ? 'supervised' : ''}`}>
					{expert.profile_picture ?
						<Canvas image={expert.profile_picture} width={150} height={150}
						        crop={expert.cropping} className={"canvas-user-profile-picture"}/>
						:
						<div className={"user-icon d-flex justify-content-center align-items-center"}>
							<FaUser color={"white"} size={100}/>
						</div>
					}
				</div>
			</div>
			<div className={'user-data d-flex flex-column w-100 px-3'}>
				{status.loaded ?
					<ContactDataBlock marketId={marketId}
					                  expert={supervisor ? supervisor : expert}
					                  supervisor={clientSupported ? false : supervisor}
					                  sms={!clientSupported}
					                  chatIntegration={chatIntegration}
					                  email={!!expert.email}
					                  status={supervisor ? status.data.supervisor : status.data.sales_representative}
					                  vcard={vcardAvailability}

					/>
					:
					<FaSpinner className={"icon-spin"} size={16}/>
				}
				{status.loaded ?
					supervisor &&
					<div className={"mt-3"}>
						<ContactDataBlock marketId={marketId}
						                  expert={expert}
						                  fullData={clientSupported ? true : !supervisor.online}
						                  sms={!clientSupported}
						                  email={!!expert.email && !clientSupported}
						                  businessHours={clientSupported}
						                  chatIntegration={chatIntegration}
						                  status={status.data.sales_representative}
						                  vcard={vcardAvailability}
						/>
					</div> :
					<FaSpinner className={"icon-spin"} size={16}/>
				}
				{displayBackup(data, status)}
			</div>
		</div>
	)
};

export default ContactBlock;
