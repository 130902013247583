import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {Geolocation} from "../../helpers/Geolocation";


const LocationAutoCompleteTextField = ({locationHandler}) => {
	const [locations, setLocations] = useState([]);
	const [suggestions, setSuggestions] = useState([]);
	const [activeSuggestion, setActiveSuggestion] = useState(0);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [userInput, setUserInput] = useState('');
	const [fetchingCurrentLocation, setFetchingCurrentLocation] = useState(false);
	const [useCurrentLocation, setUseCurrentLocation] = useState(true);
	const [currentLocationError, setCurrentLocationError] = useState(false);
	const [fetchLocationError, setFetchLocationError] = useState(false);

	useEffect(() => {
		if (navigator.geolocation) {
			if (navigator.permissions && navigator.permissions.query) {
				navigator.permissions
					.query({name: "geolocation"})
					.then((result) => {
						if (result.state === "denied") {
							setUseCurrentLocation(false);
						}
					});
			}
		}
	}, []);

	useEffect(() => {
		if (fetchLocationError) {
			geolocation.alertFetchError();
		}

	}, [fetchLocationError]);

	const httpForbiddenHandler = () => {
		setSuggestions([]);
		setShowSuggestions(false);
	}

	const geolocation = new Geolocation(
		locations,
		locationHandler,
		setUserInput,
		fetchingCurrentLocation,
		setFetchingCurrentLocation,
		useCurrentLocation,
		setUseCurrentLocation,
		currentLocationError,
		setCurrentLocationError,
		setFetchLocationError,
		httpForbiddenHandler);

	const onChangeHandler = async (event) => {
		const searchText = event.currentTarget.value;
		setUserInput(searchText);
		if (searchText.length > 4) {
			try {
				const places = await geolocation.fetchLocation(searchText);
				if (places) {
					setLocations(places);
					setSuggestions(places.map(place => place.name));
					setShowSuggestions(true);
				}
			} catch (e) {
				console.log(e);
			}
		} else if (searchText.length === 0) {
			locationHandler(0, 0);
		}
	};

	const onClickHandler = (event) => {
		const place = event.currentTarget.innerText;
		setActiveSuggestion(0);
		setShowSuggestions(false);
		setUserInput(place);
		geolocation.getLocation(place);
	};

	const onKeyDownHandler = (event) => {
		switch (event.keyCode) {
			case 13:
				event.preventDefault(); // ADA ???
				setActiveSuggestion(0);
				setShowSuggestions(false);
				setUserInput(suggestions[activeSuggestion]);
				break;
			case 38: //Up
				if (activeSuggestion === 0) {
					return;
				}
				setActiveSuggestion(activeSuggestion - 1);
				break;
			case 40: // Down
				if (activeSuggestion - 1 === suggestions.length) {
					return;
				}
				setActiveSuggestion(activeSuggestion + 1);
		}
	};

	const SuggestionsList = ({suggestions}) => {
		if (suggestions.length) {
			return (
				<ul className={"suggestions"}>
					{suggestions.map((suggestion, index) => {
						let className;
						if (index === activeSuggestion) {
							className = 'suggestion-active';
						}
						return (
							<li className={className} key={index} onClick={onClickHandler}>{suggestion}</li>
						);
					})}
				</ul>
			);
		} else {
			return (<div className={"no-suggestions"}><em>Unable to match your text</em></div>);
		}
	};

	return (
		<Form.Group controlId={"formGroupLocation"}>
			<Form.Control
				type={"text"}
				placeholder={"Enter address or ZIP code and State"}
				size={"lg"}
				onChange={onChangeHandler}
				onKeyDown={onKeyDownHandler}
				value={userInput}
				autoComplete={"off"}
			/>
			{showSuggestions && userInput && <SuggestionsList suggestions={suggestions}/>}
			<p className={`mt-2 text-right pr-1 current-location-text ${!useCurrentLocation && 'disabled'}`}
			   onClick={useCurrentLocation && !fetchingCurrentLocation ? geolocation.getCurrentLocation : !useCurrentLocation ? geolocation.alertNoGeoLocation : undefined}>
				{geolocation.currentLocationText()}
			</p>
		</Form.Group>
	);
};

export default LocationAutoCompleteTextField;
