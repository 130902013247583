import ls from "local-storage";
import moment from "moment";

export const formatPhoneNumber = (phoneNumberString) => {
	const cleaned = ("" + phoneNumberString).replace(/\D/g, "")
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
	if (match) {
		return "(" + match[1] + ") " + match[2] + "-" + match[3]
	}
	return null
}

export const ConditionalWrapper = ({condition, wrapper, children}) => (condition ? wrapper(children) : children);

export const isIos = () => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	return /iphone|ipad|ipod/.test(userAgent);
}

export const isInStandAloneMode = () => (window.matchMedia('(display-mode: standalone)').matches);

export const checkDismissal = (storage_key) => {
	const dismissed = ls.get(storage_key);
	return !dismissed || moment(dismissed) < moment()
}

export const isEmpty = (obj) => {
	return Object.keys(obj).length === 0;
}

export const urlBase64ToUint8Array = (base64String) => {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
		.replace(/\-/g, '+')
		.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
