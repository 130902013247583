import React, {useContext, useEffect} from "react";
import {MemberContext} from "../../context/MemberContext";
import {Col, Row} from "react-bootstrap";
import TeamLayout from "../TeamLayout/TeamLayout";
import RetailerResourcesBlock from "../../components/RetailerResourcesBlock/RetailerResourcesBlock";
import MemberLoginBlock from "../../components/MemberLoginBlock/MemberLoginBlock";
import {PageView} from "../../helpers/tracking";

const RetailerResources = ({teamId}) => {

	useEffect(() => {
		PageView(`Resources - ${teamId}`)
	}, []);

	const {member} = useContext(MemberContext);

	return (
		<TeamLayout teamId={teamId}>
			<Row className={"d-flex justify-content-center"}>
				<Col className={"market-block"} id={"retailer-resources-block"}>
					{member.retailer ?
						<RetailerResourcesBlock teamId={teamId}/>
						:
						<MemberLoginBlock teamId={teamId}/>
					}
				</Col>
			</Row>
		</TeamLayout>
	);

};

export default RetailerResources
