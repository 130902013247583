import React from "react";
import {mapboxApiKey, mapboxApiURL} from "../config";
import axios from "axios";
import {Slide, toast} from "react-toastify";

export class Geolocation {
	constructor(locations,
	            locationHandler,
	            setUserInput,
	            fetchingCurrentLocation,
	            setFetchingCurrentLocation,
	            useCurrentLocation,
	            setUseCurrentLocation,
	            currentLocationError,
	            setCurrentLocationError,
	            setFetchLocationError,
	            httpForbiddenHandler) {
		this.locations = locations;
		this.locationHandler = locationHandler;
		this.setUserInput = setUserInput;
		this.fetchingCurrentLocation = fetchingCurrentLocation;
		this.setFetchingCurrentLocation = setFetchingCurrentLocation;
		this.useCurrentLocation = useCurrentLocation;
		this.setUseCurrentLocation = setUseCurrentLocation;
		this.currentLocationError = currentLocationError;
		this.setCurrentLocationError = setCurrentLocationError;
		this.setFetchLocationError = setFetchLocationError;
		this.httpForbiddenHandler = httpForbiddenHandler;
	}

	// Passed a string it will try to get a location using the mapbox API
	fetchLocation = async (searchText) => {
		const url = `${mapboxApiURL}/mapbox.places/${searchText}.json`;
		try {
			const response = await axios.get(url, {params: {country: 'US', access_token: mapboxApiKey}});
			return response.data.features.map((location) => {
				return {
					name: location.place_name,
					coords: location.geometry.coordinates
				};
			})
		} catch (e) {
			if (e.response.status === 403) {
				this.httpForbiddenHandler();
			} else {
				this.setFetchLocationError(true);
			}
		}
	};

	// Used on click to return location data from a list of locations
	getLocation = (place) => {
		const location = this.locations.filter((location) => location.name.indexOf(place) > -1);
		const lng = location[0].coords[0]
		const lat = location[0].coords[1];
		this.locationHandler(lat, lng);
	};

	//Used on click to return the user's current location data
	getCurrentLocation = () => {
		this.setFetchingCurrentLocation(true);
		navigator.geolocation.getCurrentPosition(async (pos) => {
			const crd = pos.coords;
			const url = `${mapboxApiURL}/mapbox.places/${crd.longitude},${crd.latitude}.json`;
			try {
				const response = await axios.get(url, {
					params: {
						country: ['us', 'mx'],
						types: ['district', 'place', 'locality', 'neighborhood', 'address', 'poi'],
						access_token: mapboxApiKey
					}
				});
				this.setUserInput(response.data.features[0].place_name);
				this.locationHandler(crd.latitude, crd.longitude);
			} catch (e) {
				console.log(e);
				this.setCurrentLocationError(true)
			}
			this.setFetchingCurrentLocation(false);
		}, () => {
			this.setUseCurrentLocation(false);
			this.geoLocationMessage();
			this.setFetchingCurrentLocation(false);
		});
	};

	geoLocationMessage = () => {
		return (
			<div className={"text-center"}>
				You have disabled geolocation for this app. To user your current location, please activate geolocation
				in your browser.
			</div>
		)
	};

	fetchErrorMessage = () => {
		return (
			<div className={"text-center"}>
				We are unable to fetch locations.<br/> Please check your internet connection or try again
				later.
			</div>
		);
	};

	alertNoGeoLocation = () => toast.warn(this.geoLocationMessage(), {
			toastId: "geolocation-disabled",
			position: "top-center",
			autoClose: false,
			hideProgressBar: true,
			transition: Slide
		}
	);

	alertFetchError = () => toast.error(this.fetchErrorMessage(), {
		toastId: "location-fetch-error",
		position: "top-center",
		autoClose: false,
		hideProgressBar: true,
		transition: Slide,
		onClose: this.setFetchLocationError(false)
	});


	currentLocationText = () => {
		let message;
		if (this.currentLocationError) {
			message = 'An error occurred while fetching your location. Please try again';
		} else if (this.useCurrentLocation) {
			if (!this.fetchingCurrentLocation) {
				message = "Use current location";
			} else {
				message = "Fetching current location...";
			}
		} else {
			message = "Geolocation disabled";
		}
		return message;
	};

}
