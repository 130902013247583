import React, {useContext} from "react";
import {Carousel} from "react-bootstrap"

import {ConditionalWrapper} from "../../helpers/helpers";
import {GALink} from "../../helpers/tracking";
import styled from "styled-components";
import {TeamContext} from "../../context/TeamContext";

const CTAStyledButton = styled.div`
  color: ${props => props.theme ? props.theme.brand_color : 'white'} !important;
  background-color: ${props => props.theme ? props.theme.brand_accent_color : 'gray'} !important;
`;

const CarouselBlock = ({promotions}) => {
	const {team} = useContext(TeamContext);
	const theme = {
		'brand_color': team.data.brand_color,
		'brand_accent_color': team.data.brand_accent_color
	};
	return (
		<Carousel>
			{promotions.map((promotion, index) => {
				return (
					<Carousel.Item key={index}>
						<ConditionalWrapper
							condition={promotion.link}
							wrapper={(children) => <GALink label={"promotionImageClick"}
							                               to={promotion.link} id={"promotion_link"}
							                               className={"w-100"}>{children}</GALink>}
						>
							<CTAStyledButton className={"cta text-center p-3"}
							                 theme={theme}>{promotion.link_text}</CTAStyledButton>
						</ConditionalWrapper>
						<ConditionalWrapper
							condition={promotion.link}
							wrapper={(children) => <GALink label={"promotionImageClick"}
							                               to={promotion.link} id={"promotion_link"}
							                               className={"w-100"}>{children}</GALink>}
						>
							<img src={promotion.image} alt={promotion.title}
							     className={"d-block w-100"}/>
						</ConditionalWrapper>
					</Carousel.Item>);
			})}
		</Carousel>);


};

export default CarouselBlock;
