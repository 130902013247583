import React, {useContext, useEffect, useState} from "react";
import {navigate} from "@reach/router";
import moment from "moment";
import ls from 'local-storage'
import {Container, Row, Col, Button} from "react-bootstrap";
import {IoShareOutline} from "react-icons/io5"
import {Slide, toast} from "react-toastify";
import {BsPlusSquare} from "react-icons/bs"

import {checkDismissal, isInStandAloneMode, isIos} from "../../helpers/helpers";
import {TeamContext} from "../../context/TeamContext";
import styled from "styled-components";

const BannerStyledButton = styled.button`
  background-color: ${props => props.theme ? props.theme.brand_accent_color : 'grey'} !important;
  color: ${props => props.theme ? props.theme.brand_color : 'white'} !important;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  height: 60px;
  border-radius: 0;
`;

const AddToHomeScreen = ({children}) => {
	const [prompt, setPrompt] = useState(null);
	const [showButton, setShowButton] = useState(checkDismissal('pwa_dismissal'));
	const {team} = useContext(TeamContext);
	const dismissedDays = 3;

	//Check conditions to show Android installation button
	useEffect(() => {
		const handle_storePrompt = (e) => {
			e.preventDefault();
			if (showButton) setPrompt(e);
		}

		window.addEventListener('beforeinstallprompt', (e) => {
			handle_storePrompt(e)
		})

		return () => window.removeEventListener('beforinstallprompt', (e) =>
			handle_storePrompt(e)
		)
	}, [showButton]);

	// Check conditions to show iOS installation button
	useEffect(() => {
		if (isIos() && !isInStandAloneMode() && checkDismissal('pwa_dismissal')) {
			notify();
		}
	}, []);

	const handle_prompt = () => {
		setShowButton(false);
		prompt.prompt();
		setPrompt(null);

		prompt.userChoice.then((result) => {
			if (result.outcome === 'accepted')
				navigate("/")

			if (result.outcome === 'dismissed') {
				ls.set(
					'pwa_dismissal',
					moment().add(dismissedDays, 'days').toISOString()
				)
				navigate("/")
			}
		})
	};

	const notify = () => toast(<Message/>,
		{
			toastId: "add-to-home",
			position: "bottom-center",
			autoClose: false,
			hideProgressBar: true,
			transition: Slide,
			onClose: () => {
				ls.set(
					'pwa_dismissal',
					moment().add(1, 'days').toISOString()
				)
			}
		}
	);

	const Message = () => {
		return (
			<div className={"text-center"}>
				<Container fluid={true}>
					<Row>
						<Col xs={1}/>
						<Col>
							Install this app on your iPhone: Tap <IoShareOutline
							style={{bottom: 2, position: 'relative'}}/> and Add to Home Screen <BsPlusSquare
							style={{bottom: 2, position: 'relative', marginLeft: 5}} size={14}/>
						</Col>
						<Col xs={1}/>
					</Row>
				</Container>
			</div>
		)
	};

	if (team.loaded) {
		const theme = {
			'brand_color': team.data.brand_color,
			'brand_accent_color': team.data.brand_accent_color
		};

		return (
			<React.Fragment>
				<div className={team.data.class_name}>
					{showButton && prompt &&
						<React.Fragment>
							<BannerStyledButton
								theme={theme}
								onClick={handle_prompt}
								className={"btn btn-primary btn-block"}
							>
								Add App to Home Screen
							</BannerStyledButton>
						</React.Fragment>
					}
					{children}
				</div>
			</React.Fragment>
		);
	} else {
		return <React.Fragment/>;
	}
};

export default AddToHomeScreen;
