import React, {useEffect, useState} from "react";
import ls from "local-storage";
import moment from "moment";
import {Slide, toast} from "react-toastify";
import {checkDismissal} from "../../helpers/helpers";
import {Button} from "react-bootstrap";
import styled from "styled-components";

const MessageStyledButton = styled.button`
  border-color: ${props => props.theme ? props.theme.brand_accent_color === '#FFFFFF' ? 'grey' : props.theme.brand_accent_color : 'grey'} !important;
  color: ${props => props.theme ? props.theme.brand_accent_color === '#FFFFFF' ? 'grey' : props.theme.brand_accent_color : 'grey'} !important;
`;

const UsageWarning = ({team}) => {
		const [showUsageWarning, setUsageWarning] = useState(checkDismissal('usage_dismissal'));

		const theme = {
			'brand_color': team.brand_color,
			'brand_accent_color': team.brand_accent_color
		};

		useEffect(() => {
			const dismissed = ls.get('usage_dismissal');
			setUsageWarning(!dismissed || moment(dismissed) < moment());
			showUsageWarning && notify();
		}, []);

		const Message = ({closeToast}) => {
			const appName = team.app_name ? team.app_name : 'Ask an Expert';
			return (
				<div className={team.class_name}>
					<p style={{color: theme.brand_accent_color === '#FFFFFF' ? 'grey' : theme.brand_accent_color}}>{appName} is intended to be a resource while in store and
						should never be used outside of
						work
						hours.</p>
					<MessageStyledButton
						type="button"
						className={"btn mt-5 btn-outline-primary btn-block"}
						theme={theme}
						onClick={closeToast}>
						Agree
					</MessageStyledButton>
				</div>
			)
		}

		const notify = () => toast(<Message/>,
			{
				toastId: "usage-warning",
				position: "top-center",
				autoClose: false,
				hideProgressBar: true,
				transition: Slide,
				closeButton: false,
				closeOnClick: false,
				onClose: () => {
					ls.set(
						'usage_dismissal',
						moment().add(3, 'days').toISOString()
					);
				}
			}
		);

		return <React.Fragment/>;
	}
;

export default UsageWarning;
