import React, {useContext, useState} from "react";
import ResourceButton from "../ResourceButton/ResourceButton";
import {Collapse} from "react-bootstrap";
import styled from "styled-components";
import {TeamContext} from "../../context/TeamContext";
import {eventClick} from "../../helpers/tracking";

const CategoryStyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme ? props.theme.brand_color : 'white'} !important;
  color: ${props => props.theme ? props.theme.brand_accent_color : '#212529'};
`;

const CategoryButton = ({category}) => {

	const {team} = useContext(TeamContext);
	const [showResources, setShowResources] = useState(false);
	const theme = category.themed ? {
		'brand_color': team.data.brand_color,
		'brand_accent_color': team.data.brand_accent_color
	} : category.themed;

	const resourceCategoryClick = (category, action, label) => {
		setShowResources(!showResources);
		if (showResources) {
			eventClick(category, action, label);
		}
	}

	return (
		<React.Fragment>
			<CategoryStyledButton className={"category-button"}
			                      onClick={() => resourceCategoryClick("Resource Category", "Click", category.title)}
			                      aria-controls="collapse-resources"
			                      aria-expanded={showResources}
			                      theme={theme}
			>
				{category.image ?
					category.themed ?
						<div className={"d-flex align-items-center"}>
							<img src={category.image} alt={category.title} className={"mr-3"}/>
							<h5 className={"mb-0"}>{category.title}</h5>
						</div> : <img src={category.image} alt={category.title}/> :
					<h5>{category.title}</h5>
				}
			</CategoryStyledButton>
			<Collapse in={showResources}>
				<div id={"collapse-resources"}>
					{category.resources.map((resource, index) =>
						<ResourceButton resource={resource} key={index} classes={"my-3"}/>
					)}
				</div>
			</Collapse>
		</React.Fragment>
	);
};

export default CategoryButton;
