import React, {createContext, useEffect, useReducer} from "react";
import {reducer} from "../reducers/reducer";
import {clear} from "../actions/actions";

const initialValues = {
	loaded: false,
	loading: false,
};

export const UserContext = createContext({});

const UserProvider = ({children}) => {

	const initialState = JSON.parse(localStorage.getItem("user")) &&
		{
			loaded: true,
			data: JSON.parse(localStorage.getItem("user"))
		} || initialValues;

	const [user, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (user.data) {
			localStorage.setItem("user", JSON.stringify(user.data));
		}
	}, [user.data]);

	const clearUser = () => {
		localStorage.removeItem("user")
		dispatch(clear())
	};

	return (
		<UserContext.Provider value={{user, dispatch, clearUser}}>
			{children}
		</UserContext.Provider>
	);
};

export default UserProvider;
