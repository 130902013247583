import React from "react";
import {Col, Row} from "react-bootstrap";
import CategoryButton from "../CategoryButton/CategoryButton";

const CategoryList = ({categories, resources}) => {
	const categories_count = resources ? 1 + categories.length : categories.length;
	const col_size = categories_count === 1 ? 12 : !categories_count % 2 ? 3 : 6;

	return (
		<React.Fragment>
			<h2 className={"mb-3 mb-lg-5 text-center"}>Resource Categories</h2>
			<Row>
				{categories_count ?
					categories.map((category, index) => {
						return (
							<Col xs={12} sm={col_size} className={"mb-3 category-column"} key={index}>
								<CategoryButton category={category}/>
							</Col>
						);
					})
					:
					<Col xs={12}>
						<p className={"text-center"}>Sorry, we can't find any resources right now. Please check
							back later.</p>
					</Col>
				}
				{resources &&
				<Col xs={12} sm={col_size}>
					<CategoryButton category={{
						'title': 'Additional Resources',
						'path': 'all_other',
						'image': null,
						'resources': resources
					}}/>
				</Col>
				}
			</Row>
		</React.Fragment>
	);
};

export default CategoryList;
