import React, {useEffect, useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import axios from "axios";
import {FaSpinner} from "react-icons/fa";
import {apiURL, mapboxApiKey, mapboxApiURL} from "../../config";
import LocationAutoCompleteTextField from "../LocationAutoCompleteField/LocationAutoCompleteField";
import ScheduleCard from "../ScheduleCard/ScheduleCard";
import {Slide, toast} from "react-toastify";

const ScheduleBlock = ({market_id, show_time}) => {
	const [schedule, setSchedule] = useState({data: [], loaded: false});
	const [location, setLocation] = useState({lat: null, lng: null});
	const [fetching, setFetching] = useState(false);
	const [fetchScheduleError, setFetchScheduleError] = useState(false);

	const dummyStore = {
		store_number: '1234',
		retailer: {name: 'Best Buy'},
		city: 'Los Angeles',
		state: 'CA',
		zip: '90038',
		address: '1015 N La Brea Ave, West Hollywood',
		phone: '(323) 883 0219',
		visit_set: [
			{
				start_time: {format: 'block', visit_hours: ['11:40:00', '13:00:00']},
				date: '2022-07-27'
			},
			{
				start_time: {format: 'block', visit_hours: ['9:00:00', '11:00:00']},
				date: '2022-08-01'
			}
		]
	}

	useEffect(() => {
		if (fetchScheduleError) {
			alertFetchScheduleError();
		}

	}, [fetchScheduleError]);

	const fetchSchedule = async () => {
		const url = `${apiURL}/schedule/${market_id}`;
		setSchedule({data: [], loaded: false});
		try {
			setFetching(true);

			if (market_id === '758b62f1-b4d4-4d6a-9ca1-9aad4cb1798c') {
				setSchedule({
					data: [dummyStore,],
					loaded: true
				});
			} else {
				const response = await axios.get(url, {params: {lat: location.lat, lng: location.lng}});
				if (response.data.length) {
					setSchedule({data: response.data, loaded: true});
				} else {
					setSchedule({data: [], loaded: true});
				}
			}
		} catch (e) {
			console.log(e);
		}
		setFetching(false);
	};

	const setCurrentLatLng = (lat, lng) => {
		setLocation({lat: lat, lng: lng});
	};

	const alertFetchScheduleError = () => toast.error(fetchScheduleErrorMessage(), {
		toastId: "location-fetch-error",
		position: "top-center",
		autoClose: false,
		hideProgressBar: true,
		transition: Slide,
		onClose: setFetchScheduleError(false)
	});

	const fetchScheduleErrorMessage = () => {
		return (
			<div className={"text-center"}>
				We are unable to fetch the schedule.<br/> Please check your internet connection or try again
				later.
			</div>
		);
	};

	return (
		<div className={"d-flex flex-column h-100"}>
			<h2 className={"mb-4 text-center"}>Upcoming schedule</h2>
			<Form>
				<Row>
					<Col md={8}>
						<LocationAutoCompleteTextField locationHandler={setCurrentLatLng}/>
					</Col>
					<Col>
						<Button variant="primary" block size={"lg"}
						        onClick={!fetching ? fetchSchedule : undefined}
						        disabled={!location.lat && !location.lng}>
							{fetching ?
								<FaSpinner className={"icon-spin"}/>
								:
								<React.Fragment>
									Search
								</React.Fragment>
							}

						</Button>
					</Col>
				</Row>
			</Form>
			<Container className={"location-results"}>
				{schedule.loaded && schedule.data.length ?
					schedule.data.map((store) => {
						return (
							<ScheduleCard store={store} show_time={show_time} key={store.store_number}/>
						);
					})
					:
					schedule.loaded &&
					<div className={"text-center"}>No visits programmed in your vicinity</div>
				}
			</Container>
		</div>
	);
}

export default ScheduleBlock
