import React from "react";
import {Navbar} from "react-bootstrap";
import styled from "styled-components";

const NavbarStyled = styled.nav`
  border-bottom-color: ${props => props.theme ? props.theme.brand_accent_color : 'grey'} !important;
  min-height: 85px;
`;


const MarketHeader = ({team}) => {
	const theme = {
		'brand_color': team.brand_color,
		'brand_accent_color': team.brand_accent_color
	};

	return (
		<NavbarStyled className={`navbar navbar-expand-lg navbar-light bg-light ${team.class_name}`} theme={theme}>
			<Navbar.Brand href={"/"}>
				{team.logo ? <img src={team.logo} alt={`${team.class_name}-logo`}
				                  className={`brand-logo ${team.chat_integration ? 'chat-available' : ''}`}/> :
					team.name
				}
			</Navbar.Brand>
		</NavbarStyled>
	);
}
export default MarketHeader;
